// 'use client'

// import { CartItem } from '@/interfaces/cart-item.interface';
// import { createContext, useContext, useReducer, useEffect } from 'react';
// import { openDB } from 'idb';

// // Definimos la interfaz del estado del carrito
// interface CartState {
//   items: Array<CartItem>;
//   total: number;
//   totalWithCommission: number;
//   totalWithStorePercentage: number;
//   totalWithIva: number;
//   commissionAmount: number;
//   storePercentageAmount: number;
//   ivaAmount: number;
// }

// // Definimos la interfaz para las acciones del carrito
// interface CartAction {
//   type: 'INIT' | 'ADD_ITEM' | 'REMOVE_ITEM' | 'UPDATE_QUANTITY' | 'CLEAR_CART';
//   payload: any;
// }

// // Función para abrir la base de datos y obtener una referencia a ella
// const getDB = async () => {
//   return await openDB('comprainteligentedb', 1, {
//     upgrade(db) {
//       if (!db.objectStoreNames.contains('cart')) {
//         db.createObjectStore('cart', { keyPath: 'id' });
//       }
//     },
//   });
// };

// // Función para cargar el estado inicial desde IndexedDB
// const loadStateFromIndexedDB = async (): Promise<CartState> => {
//   try {
//     const db = await getDB();
//     if (!db) {
//       throw new Error('Failed to open IndexedDB');
//     }

//     const tx = db.transaction('cart', 'readonly');
//     const store = tx.objectStore('cart');
//     const getRequest = await store.get(1);

//     // Esperar a que la solicitud de obtención se resuelva
//     const state = await getRequest;

//     console.log('Loaded state from IndexedDB', state);

//     return state || { items: [], total: 0, totalWithCommission: 0, totalWithStorePercentage: 0, totalWithIva: 0, commissionAmount: 0, storePercentageAmount: 0, ivaAmount: 0 }; // Estado inicial predeterminado si no hay datos
//   } catch (err) {
//     console.error('Failed to load state from IndexedDB', err);
//     return { items: [], total: 0, totalWithCommission: 0, totalWithStorePercentage: 0, totalWithIva: 0, commissionAmount: 0, storePercentageAmount: 0, ivaAmount: 0 }; // Estado inicial predeterminado en caso de error
//   }
// };

// // Función para guardar el estado en IndexedDB
// const saveStateToIndexedDB = async (state: CartState) => {
//   try {
//     const db = await getDB();
//     await db.put('cart', { ...state, id: 1 });
//   } catch (err) {
//     console.error('Failed to save state to IndexedDB', err);
//   }
// };

// // Función para calcular los diferentes precios
// const calcularPrecios = (precioBase: number, porcentajeTienda: number) => {
//   const comision = 0.035; // 3.5%
//   const iva = 0.16; // 16%

//   console.log('precioBase', precioBase);

//   const commissionAmount = parseFloat((precioBase * comision).toFixed(2));
//   const storePercentageAmount = parseFloat((precioBase * (porcentajeTienda / 100)).toFixed(2));
//   const ivaAmount = parseFloat(((precioBase + commissionAmount + storePercentageAmount) * iva).toFixed(2));

//   const totalWithCommission = parseFloat((precioBase + commissionAmount).toFixed(2));
//   const totalWithStorePercentage = parseFloat((precioBase + storePercentageAmount).toFixed(2));
//   const totalWithIva = parseFloat((precioBase + ivaAmount).toFixed(2));

//   const totalFinal = (
//     precioBase +
//     commissionAmount +
//     storePercentageAmount +
//     ivaAmount
//   ).toFixed(2);

//   return {
//     totalWithCommission,
//     totalWithStorePercentage,
//     totalWithIva,
//     commissionAmount,
//     storePercentageAmount,
//     ivaAmount,
//     total: parseFloat(totalFinal) //totalWithIva // Total incluye todas las comisiones y el IVA
//   };
// };

// // Creamos el contexto
// const CartContext = createContext<{ state: CartState, dispatch: React.Dispatch<CartAction> }>({
//   state: {
//     items: [],
//     total: 0,
//     totalWithCommission: 0,
//     totalWithStorePercentage: 0,
//     totalWithIva: 0,
//     commissionAmount: 0,
//     storePercentageAmount: 0,
//     ivaAmount: 0
//   },
//   dispatch: () => null
// });

// export function CartWrapper({ children }: { children: any }) {
//   // Reducer para manejar las acciones del carrito
//   const reducer = (state: CartState, action: CartAction): CartState => {
//     switch (action.type) {
//       case 'INIT': {
//         return action.payload;
//       }

//       case 'ADD_ITEM': {
//         const item = action.payload;
//         const existingItemIndex = state.items.findIndex(i => i.productId === item.productId);

//         let newItems;
//         if (existingItemIndex !== -1) {
//           newItems = [...state.items];
//           const existingItem = newItems[existingItemIndex];
//           const newQuantity = existingItem.quantitySelected + item.quantitySelected;
//           newItems[existingItemIndex] = {
//             ...existingItem,
//             quantitySelected: newQuantity,
//             totalItemPrice: newQuantity * existingItem.price,
//           };
//         } else {
//           newItems = [
//             ...state.items,
//             {
//               ...item,
//               totalItemPrice: item.quantitySelected * parseFloat(item.price),
//             }
//           ];
//         }

//         const newTotal = newItems.reduce((sum, item) => sum + item.totalItemPrice, 0);

//         // Calcular los diferentes precios
//         const { totalWithCommission, totalWithStorePercentage, totalWithIva, commissionAmount, storePercentageAmount, ivaAmount, total } = calcularPrecios(newTotal, 20); // Aquí puedes cambiar el porcentaje de la tienda

//         saveStateToIndexedDB({
//           items: newItems,
//           total,
//           totalWithCommission,
//           totalWithStorePercentage,
//           totalWithIva,
//           commissionAmount,
//           storePercentageAmount,
//           ivaAmount
//         });

//         return {
//           items: newItems,
//           total,
//           totalWithCommission,
//           totalWithStorePercentage,
//           totalWithIva,
//           commissionAmount,
//           storePercentageAmount,
//           ivaAmount
//         };
//       }

//       case 'REMOVE_ITEM': {
//         const itemId = action.payload;
//         console.log('itemId', itemId)
//         const newItems = state.items.filter(item => item.productId !== itemId.productId);
//         const newTotal = newItems.reduce((sum, item) => sum + (item.totalItemPrice || 0), 0);

//         // Calcular los diferentes precios
//         const { totalWithCommission, totalWithStorePercentage, totalWithIva, commissionAmount, storePercentageAmount, ivaAmount, total } = calcularPrecios(newTotal, 15); // Aquí puedes cambiar el porcentaje de la tienda

//         console.log('newItems after removal', newItems);

//         // Guardar el estado actualizado en IndexedDB
//         saveStateToIndexedDB({
//           items: newItems,
//           total,
//           totalWithCommission,
//           totalWithStorePercentage,
//           totalWithIva,
//           commissionAmount,
//           storePercentageAmount,
//           ivaAmount
//         });

//         return {
//           items: newItems,
//           total,
//           totalWithCommission,
//           totalWithStorePercentage,
//           totalWithIva,
//           commissionAmount,
//           storePercentageAmount,
//           ivaAmount
//         };
//       }

//       case 'UPDATE_QUANTITY': {
//         const { id, quantity } = action.payload;
//         const newItems = state.items.map(item =>
//           item.productId === id
//             ? {
//               ...item,
//               quantitySelected: Math.max(0, quantity), // Asegurar que la cantidad no sea negativa
//               totalItemPrice: Math.max(0, quantity) * item.price, // Calcular el precio total del artículo
//             }
//             : item
//         );

//         // Calcular el nuevo total global sumando los totalItemPrice de todos los items
//         const newTotal = newItems.reduce((sum, item) => sum + (item.totalItemPrice || 0), 0);

//         // Calcular los diferentes precios
//         const { totalWithCommission, totalWithStorePercentage, totalWithIva, commissionAmount, storePercentageAmount, ivaAmount, total } = calcularPrecios(newTotal, 15); // Aquí puedes cambiar el porcentaje de la tienda

//         console.log('newItems', newItems);

//         // Guardar el estado actualizado en IndexedDB
//         saveStateToIndexedDB({
//           items: newItems,
//           total,
//           totalWithCommission,
//           totalWithStorePercentage,
//           totalWithIva,
//           commissionAmount,
//           storePercentageAmount,
//           ivaAmount
//         });

//         return {
//           items: newItems,
//           total,
//           totalWithCommission,
//           totalWithStorePercentage,
//           totalWithIva,
//           commissionAmount,
//           storePercentageAmount,
//           ivaAmount
//         };
//       }

//       case 'CLEAR_CART': {
//         // Guardar el estado vacío en IndexedDB
//         saveStateToIndexedDB({
//           items: [],
//           total: 0,
//           totalWithCommission: 0,
//           totalWithStorePercentage: 0,
//           totalWithIva: 0,
//           commissionAmount: 0,
//           storePercentageAmount: 0,
//           ivaAmount: 0
//         });

//         return {
//           items: [],
//           total: 0,
//           totalWithCommission: 0,
//           totalWithStorePercentage: 0,
//           totalWithIva: 0,
//           commissionAmount: 0,
//           storePercentageAmount: 0,
//           ivaAmount: 0
//         };
//       }

//       default:
//         return state;
//     }
//   };

//   const [state, dispatch] = useReducer(reducer, {
//     items: [],
//     total: 0,
//     totalWithCommission: 0,
//     totalWithStorePercentage: 0,
//     totalWithIva: 0,
//     commissionAmount: 0,
//     storePercentageAmount: 0,
//     ivaAmount: 0
//   });

//   useEffect(() => {
//     loadStateFromIndexedDB().then(initialState => {
//       dispatch({ type: 'INIT', payload: initialState });
//     });
//   }, []);

//   return (
//     <CartContext.Provider value={{ state, dispatch }}>
//       {children}
//     </CartContext.Provider>
//   );
// }

// // Custom hook para usar el carrito
// // export const useCart = () => useContext(CartContext);
// // Hook personalizado para usar el contexto del carrito
// export function useCartContext() {
//   return useContext(CartContext);
// }



'use client'

import { CartItem } from '@/interfaces/cart-item.interface';
import { createContext, useContext, useReducer, useEffect } from 'react';
import { openDB } from 'idb';

// Definimos la interfaz del estado del carrito
interface CartState {
  items: Array<CartItem>;
  total: number;
}

// Definimos la interfaz para las acciones del carrito
interface CartAction {
  type: 'INIT' | 'ADD_ITEM' | 'REMOVE_ITEM' | 'UPDATE_QUANTITY' | 'CLEAR_CART';
  payload: any;
}

// Función para abrir la base de datos y obtener una referencia a ella
const getDB = async () => {
  return await openDB('comprainteligentedb', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('cart')) {
        db.createObjectStore('cart', { keyPath: 'id' });
      }
    },
  });
};

// Función para cargar el estado inicial desde IndexedDB
const loadStateFromIndexedDB = async (): Promise<CartState> => {
  try {
    const db = await getDB();
    if (!db) {
      throw new Error('Failed to open IndexedDB');
    }

    const tx = db.transaction('cart', 'readonly');
    const store = tx.objectStore('cart');
    const getRequest = await store.get(1);

    // Esperar a que la solicitud de obtención se resuelva
    const state = await getRequest;

    console.log('Loaded state from IndexedDB', state);

    return state || { items: [], total: 0 }; // Estado inicial predeterminado si no hay datos
  } catch (err) {
    console.error('Failed to load state from IndexedDB', err);
    return { items: [], total: 0 }; // Estado inicial predeterminado en caso de error
  }
};

// Función para guardar el estado en IndexedDB
const saveStateToIndexedDB = async (state: CartState) => {
  try {
    const db = await getDB();
    await db.put('cart', { ...state, id: 1 });
  } catch (err) {
    console.error('Failed to save state to IndexedDB', err);
  }
};

// Función para calcular el total con comisiones y porcentajes
const calcularPrecios = (precioBase: number, porcentajeTienda: number) => {
  const comision = 0.035; // 3.5%
  const iva = 0.16; // 16%

  console.log('precioBase', precioBase);

  const commissionAmount = parseFloat((precioBase * comision).toFixed(2));
  const storePercentageAmount = parseFloat((precioBase * (porcentajeTienda / 100)).toFixed(2));
  const ivaAmount = parseFloat(((precioBase + commissionAmount + storePercentageAmount) * iva).toFixed(2));

  const total = parseFloat((precioBase + commissionAmount + storePercentageAmount + ivaAmount).toFixed(2));

  return {
    total
  };
};

// Creamos el contexto
const CartContext = createContext<{ state: CartState, dispatch: React.Dispatch<CartAction> }>({
  state: {
    items: [],
    total: 0
  },
  dispatch: () => null
});

export function CartWrapper({ children }: { children: any }) {
  // Reducer para manejar las acciones del carrito
  const reducer = (state: CartState, action: CartAction): CartState => {
    switch (action.type) {
      case 'INIT': {
        return action.payload;
      }

      case 'ADD_ITEM': {
        const item = action.payload;
        const existingItemIndex = state.items.findIndex(i => i.productId === item.productId);

        let newItems;
        if (existingItemIndex !== -1) {
          newItems = [...state.items];
          const existingItem = newItems[existingItemIndex];
          const newQuantity = existingItem.quantitySelected + item.quantitySelected;
          newItems[existingItemIndex] = {
            ...existingItem,
            quantitySelected: newQuantity,
            totalItemPrice: newQuantity * existingItem.price,
          };
        } else {
          newItems = [
            ...state.items,
            {
              ...item,
              totalItemPrice: item.quantitySelected * parseFloat(item.price),
            }
          ];
        }

        const newTotal = newItems.reduce((sum, item) => sum + item.totalItemPrice, 0);

        // Calcular el total final
        // const { total } = calcularPrecios(newTotal, 20); // Aquí puedes cambiar el porcentaje de la tienda

        saveStateToIndexedDB({
          items: newItems,
          total: newTotal
        });

        return {
          items: newItems,
          total: newTotal
        };
      }

      case 'REMOVE_ITEM': {
        const itemId = action.payload;
        console.log('itemId', itemId)
        const newItems = state.items.filter(item => item.productId !== itemId.productId);
        const newTotal = newItems.reduce((sum, item) => sum + (item.totalItemPrice || 0), 0);

        // Calcular el total final
        // const { total } = calcularPrecios(newTotal, 15); // Aquí puedes cambiar el porcentaje de la tienda

        console.log('newItems after removal', newItems);

        // Guardar el estado actualizado en IndexedDB
        saveStateToIndexedDB({
          items: newItems,
          total: newTotal
        });

        return {
          items: newItems,
          total: newTotal
        };
      }

      case 'UPDATE_QUANTITY': {
        const { id, quantity } = action.payload;
        const newItems = state.items.map(item =>
          item.productId === id
            ? {
              ...item,
              quantitySelected: Math.max(0, quantity), // Asegurar que la cantidad no sea negativa
              totalItemPrice: Math.max(0, quantity) * item.price, // Calcular el precio total del artículo
            }
            : item
        );

        // Calcular el nuevo total global sumando los totalItemPrice de todos los items
        const newTotal = newItems.reduce((sum, item) => sum + (item.totalItemPrice || 0), 0);

        // Calcular el total final
        // const { total } = calcularPrecios(newTotal, 15); // Aquí puedes cambiar el porcentaje de la tienda

        console.log('newItems', newItems);

        // Guardar el estado actualizado en IndexedDB
        saveStateToIndexedDB({
          items: newItems,
          total: newTotal
        });

        return {
          items: newItems,
          total: newTotal
        };
      }

      case 'CLEAR_CART': {
        // Guardar el estado vacío en IndexedDB
        saveStateToIndexedDB({
          items: [],
          total: 0
        });

        return {
          items: [],
          total: 0
        };
      }

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    items: [],
    total: 0
  });

  useEffect(() => {
    loadStateFromIndexedDB().then(initialState => {
      dispatch({ type: 'INIT', payload: initialState });
    });
  }, []);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
}

// Hook personalizado para usar el contexto del carrito
export function useCartContext() {
  return useContext(CartContext);
}
